import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ProvideAuth, useAuth } from '../hooks/useAuth';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from '../components/common/Toast';


const DashboardLayout = lazy(() => import('./DashboardLayout'));
const PartnerLayout = lazy(() => import('./PartnerLayout'));
const AuthBasicLayout = lazy(() => import('./AuthBasicLayout'));
const PartnerBasicLayout = lazy(() => import('./PartnerBasicLayout'));



const Layout = () => {
  return (
    <Suspense fallback={<span />}>
      <ProvideAuth>
        <Router fallback={<span />}>
          <Switch>
            <Route path="/auth" component={AuthBasicLayout} />
            <Route path="/partner" component={PartnerBasicLayout} />
            <PrivatePartnerRoute path="/partner-auth" component={PartnerLayout} />
            <PrivateRoute path="/" component={DashboardLayout} />
          </Switch>
          <ToastContainer closeButton={<CloseButton />}  position={toast.POSITION.BOTTOM_CENTER} />
        </Router>
      </ProvideAuth>
    </Suspense>
  );
};

export default Layout;

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user,loggedIn } = useAuth()
  return (<Route
    {...rest}
    render={props =>
      loggedIn() ? (
        user && (user.role === "partner" || user.role === "user_partner" )?<Redirect to={{ pathname: "/partner-auth" }} />:
        <Component {...props} />
      ) : <Redirect to={{ pathname: "/auth/login", search: `?next=${props.location.pathname}` }} />
    }
  />)
};

const PrivatePartnerRoute = ({ component: Component, ...rest }) => {
  const { loggedIn } = useAuth()
  return (<Route
    {...rest}
    render={props =>
      loggedIn() ? (
        <Component {...props} />
      ) : <Redirect to={{ pathname: "/partner/login", search: `?next=${props.location.pathname}` }} />
    }
  />)
};